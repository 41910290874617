/** @format */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
import { getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAbG6R-VG6Bq_yNxAp1P-lTRT0cTKf2d2A",
  authDomain: "crm-fladdra.firebaseapp.com",
  projectId: "crm-fladdra",
  storageBucket: "crm-fladdra.appspot.com",
  messagingSenderId: "999615421891",
  appId: "1:999615421891:web:095fa9d6ade6680c60f176",
  measurementId: "G-PR0CBX0EQW",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestFcmToken = async () => {
  try {
    const fcmToken = await getToken(messaging, {
      vapidKey:
        "BJ8BqOHbXuLkuBniZOMouUVUH1YTCyyZ295AaaYuHvzvLvA-fiDROlNP8mvpg7ZuVoEu3kRNa1ac6GBBtH1gkaM",
    });
    if (fcmToken) {
      return fcmToken;
    } else {
      console.error(
        "No registration token available. Request permission to generate one."
      );
      return null;
    }
  } catch (error) {
    console.error("An error occurred while retrieving the token.", error);
    return null;
  }
};
